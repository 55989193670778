import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import headerStyles from "./header.module.css"

const Header = ({ siteTitle }) => (
    <header className={headerStyles.header}>
        <div className={headerStyles.logo}>
            <Link to="/"><ImageHeader /></Link>
        </div>
        <div className={headerStyles.navigation}>
            <div className={headerStyles.line}><span></span></div>
            <div className={headerStyles.navigationLinks}>
              <Link to="/" activeClassName={headerStyles.activeLink}>HOME</Link>
              <Link to="/beer/" activeClassName={headerStyles.activeLink}>BEER</Link>
              <Link to="/food/" activeClassName={headerStyles.activeLink}>FOOD</Link>
              <Link to="/events/" activeClassName={headerStyles.activeLink}>EVENTS</Link>
              <Link to="/about/" activeClassName={headerStyles.activeLink}>ABOUT</Link>
              <Link to="/contact/" activeClassName={headerStyles.activeLink}>CONTACT</Link>
              {/* <a href="https://stodgybrewing.square.site/">ORDER</a> */}
            </div>
            <div className={headerStyles.line}><span></span></div>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

const ImageHeader = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "stodgy_logo_letters.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default Header
