import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import footerStyles from "./footer.module.css"

const Footer = () => (

    <footer className={footerStyles.footer}>
        <div className={footerStyles.footerLogoImage}>
          <Link to="/"><ImageFooter /></Link>
        </div>
        <div className={footerStyles.navigation}>
            <Link to="/" activeClassName={footerStyles.activeLink}>HOME</Link>
            <Link to="/beer/" activeClassName={footerStyles.activeLink}>BEER</Link>
            <Link to="/food/" activeClassName={footerStyles.activeLink}>FOOD</Link>
            <Link to="/events/" activeClassName={footerStyles.activeLink}>EVENTS</Link>
            <Link to="/about/" activeClassName={footerStyles.activeLink}>ABOUT</Link>
            <Link to="/contact/" activeClassName={footerStyles.activeLink}>CONTACT</Link>
            {/* <Link to="/hiring/" activeClassName={footerStyles.activeLink}>HIRING</Link> */}
            {/* <a href="https://stodgybrewing.square.site/">ORDER</a> */}
        </div>
        <div className={footerStyles.socialContainer}>
            <a href="https://www.facebook.com/profile.php?id=61561583229119" alt="Link to Facebook page" target="_blank" rel="noreferrer noopener">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310"><path d="M81.703 165.106h33.981V305a5 5 0 005 5H178.3a5 5 0 005-5V165.765h39.064a5 5 0 004.967-4.429l5.933-51.502a5 5 0 00-4.966-5.572h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667h29.42a5 5 0 005-5V5.037a5 5 0 00-5-5h-40.545A39.746 39.746 0 00185.896 0c-7.035 0-31.488 1.381-50.804 19.151-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703a5 5 0 00-5 5v50.844a5 5 0 005 5.001z" /></svg>
            </a>
            <a href="https://www.instagram.com/stodgybrewing" alt="Link to Instagram page" target="_blank" rel="noreferrer noopener">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.063 169.063"><path d="M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0zm31.657 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655v75.752z" /><path d="M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563 0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561c0-24.021-19.542-43.563-43.563-43.563zm0 72.123c-15.749 0-28.563-12.812-28.563-28.561 0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563c0 15.749-12.814 28.561-28.563 28.561zM129.921 28.251c-2.89 0-5.729 1.17-7.77 3.22a11.053 11.053 0 00-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z" /></svg>
            </a>
        </div>
    </footer>
)

const ImageFooter = () => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "stodgy_logo_footer_menu.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

    return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default Footer
